import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable{

    constructor(item, data) {

        super()

        return [
            item.id,
            '<b class="text-primary">' + item.command + '</b>',
            '<div class="pretty_json">' + JSON.stringify(item.result) + '</div>',
            item.executed_at
        ];
    }
}
