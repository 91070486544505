import {FormatDataTable} from '../formatDataTable';
import {TrackingLink} from "../../trackingLink";

export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super();

        this.item = item;
        let self = this;

        let trackingLink = new TrackingLink(item);

        const orderDate = new Date(item.order.created_at);
        let options = [
            {day: 'numeric'},
            {month: 'short'},
            {year: 'numeric'}
        ];
        let joined = this.join(orderDate, options, '-');

        let destroyUrl = $('#order_table').data('destroy-url').replace('##', item.order.id);
        let receiverAddress = item.order.receiver_address;

        return [
            this.id(),
            self.vendor(data),
            item.which_platform_string + self.integrationLogo(),
            '<div>' + item.order.order_id + '</div>' +
            self.lableWhenEtd() +
            self.lableIsPrinted(),
            self.getOrderStatus(),
            typeof receiverAddress.full_name != 'undefined' ? '<b>' + receiverAddress.full_name + '</b>' : '',
            self.shippingLogo(),
            trackingLink.show(),
            (item.is_express === 1 ? 'express' : 'economic'),
            item.country,
            '<div class="text-nowrap">' + joined + '</div>',

            // Shares function with vendor
            self.updateButton(data, item.order.id, 'vendorOrderModal'),
            self.createDeleteButton(item.deletable, destroyUrl, item.deletableMsg),
            self.lastButton()
        ];
    }

    join(date, options, separator) {
        function format(option) {
            let formatter = new Intl.DateTimeFormat('en', option);
            return formatter.format(date);
        }

        return options.map(format).join(separator);
    }

    getOrderStatus() {
        return `
            <span class="order_satus" style="background:#` + this.item.order.order_statuss.color + `;">
                ` + this.item.status_name + `
            </span>`;
    }

    integrationLogo() {
        let order = this.item.order;
        let integration_data = order.integration_data;

        if (order.which_platform != 7) {
            return '';
        }

        if (typeof integration_data == 'undefined') {
            return '';
        }

        if (integration_data == null) {
            return '';
        }

        if (typeof integration_data == 'undefined') {
            return '';
        }

        let integrationIcon = `<img src="/img/integrations/` + integration_data.integration_id + `.png" class="vendor-img-sm" alt="integrator" title="integrator">`;
        if( integration_data.integration_id ==  order.integration_source_id){
            integrationIcon ='';
        }

        return `
            <div class="row">
                <div class="col-6">
                ` + integrationIcon + `
                </div>
                <div class="col-6">
                    <img src="/img/integrations/` + order.integration_source_id + `.png" class="vendor-img-sm" alt="integration" title="integration">
                </div>
            </div>`;
    }

    shippingLogo() {
        let shippingId = this.item.order.shipping_id;
        if (shippingId != null) {
            return '<img src="/storage/img/shipping/' + shippingId + '.webp" alt="" style="width:30px">';
        }

        return `<div class="text-center"> - </div>`;
    }

    /**
     * Adminde bmölümünde bu parça gereksiz olabilir.
     */
    id() {
        let order = this.item.order;
        let id = order.id;

        return `
            <div
                id="order_` + id + `"
                data-order_id="` + id + `"
                data-order="` + JSON.stringify(order).replaceAll("'", '`').replaceAll('\"', "'") + `"
                data-is_admin="true"
                data-description="` + (order.description ? order.description : '') + `"
                data-is_labelled="` + (order.tracking_number == null ? 'false' : 'true') + `"
                data-is_labellable="` + order.is_labellable + `"
                data-is_labellable_reason="` + order.is_labellable_reason + `"
                data-real_status="` + order.real_status + `" >

                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="defaultCheck_` + id + `" data-selected_order_id="` + id + `">
                  <label class="form-check-label" for="defaultCheck_` + id + `">
                   ` + id + `
                  </label>
                </div>

                <button class="btn btn-danger btn-sm open-bottom-row d-none">open</button>

            </div>`
    }

    vendor(data) {
        let item = this.item;
        let userUpdateUrl = data.update_user_url.replace('###', item.order.user_id);

        return `
            <div class="text-center  open-modal" data-url="` + userUpdateUrl + `" data-onload="adminUserModal" role="button">
                <b class="text-danger">` + item.order.user.company_name + `</b>
                <br>
                 ` + item.order.user.full_name + `
            </div>`;
    }

    shipmentPrice() {
        let price = this.item.shipment_actual_price;
        if (price > 0) {
            return '<b>' + price + '</b><br><small style="color:#999">' + this.item.shipment_currency_text + '</small>'
        }

        return `<div class="text-center"> - </div>`;
    }

    lableWhenEtd() {
        if (this.item.order.is_etd) {
            return `<i class="text-danger fas fa-file-medical pr-2"></i>`;
        }

        return `<i class="text-secondary fas fa-file pr-2"></i>`;
    }

    lableIsPrinted() {
        if (this.item.order.is_printed) {
            return `<i class="fas fa-print  pr-2" style="color:#009624" title="Printed "></i>`;
        }

        return `<i class="fas fa-print secondary pr-2" title="Not printed"></i>`;
    }

    lastButton() {
        let status = this.item.order.real_status;

        let button = `
                <span class="btn btn-circle btn-sm btn-light"  role="button">
                    <i class="fas fa-question text-gray-400"></i>
                </span>`;

        if ([26, 28, 29, 32].includes(status)) {
            button = `
                <span class="btn btn-circle btn-sm btn-warning js-open-label"  role="button">
                    <i class="fas fa-folder-plus"></i>
                </span>`;
        }

        if (status === 14) {

            button = `
                <span
                    class="btn btn-circle btn-sm btn-info js-print-single-zpl-label"
                    role="button"
                    data-id="` + this.item.order.id + `" >
                    <i class="fas fa-print"></i>
                </span>`;
        }

        return ` <div class="text-center"> ` + button + ` </div>`;
    }
}
