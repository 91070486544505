import $ from "jquery";
import {formErrors} from "../../../forms/formErrors";

/**
 * let form = new ReceiverAddressForm();
 *
 * // result ajax sonucu
 * form.createAddress(result);
 */
export class ReceiverAddressForm {

    /**
     * Creates address facade, and fill form
     * @param result
     *      'address': data.address,
     *      'email': data.address.address_mail[0].mail,
     *      'phone': data.address.address_phone[0].phone,
     *      'code': data.address.address_phone[0].code,
     *      'location': data.location
     */
    createAddress(result) {
        this.result = result;
        this.fill();
        this.showAddressFacade();
    }

    fill() {
        $('input[name=receiver_address_id]').val(this.result.address.id);
        $('.js-address_go_back').trigger('click');
        $('.js-order_update_address').removeClass('d-none');

        this.fillForm();
        this.resetFormErrors();
    }

    showAddressFacade() {
        let content = $('.address_template').html();
        content = content.replace(/__FULL_NAME__/g, this.result.address.full_name);
        content = content.replace(/__COMPANY_NAME__/g, this.result.address.full_name);
        content = content.replace(/__EMAIL__/g, this.result.email);
        content = content.replace(/__CODE__/g, this.result.code);
        content = content.replace(/__PHONE__/g, this.result.phone);
        content = content.replace(/__ADDRESS__/g, this.result.address.address);
        content = content.replace(/__LOCATION__/g, this.result.location.text);
        content = content.replace(/__POSTAL_CODE__/g, this.result.address.postal_code);

        $('#receiver-address').html(content);
    }

    selectIntlPhoneCode(code) {
        try {
            const input = document.querySelector('.js-int-phone');
            const iti = window.intlTelInputGlobals.getInstance(input);
            iti.setCountry(code);
        } catch (e) {

        }
    }

    fillForm() {

        $('#address #full_name').val(this.result.address.full_name);
        $('#address #company_name').val(this.result.address.company_name);
        $('#address #phone').val(this.result.phone);
        $('#address #address').val(this.result.address.address);
        $('#address #email').val(this.result.email);
        $('#address #postal_code').val(this.result.address.postal_code);
        $('#address #phone_code').val(this.result.code);

        this.selectIntlPhoneCode(this.result.phone_country_code);

        let countryOption = new Option(this.result.location.country_name, this.result.location.country_id, false, true);
        $('select[name=country_id]').append(countryOption).trigger('change');

        let stateOption = new Option(this.result.location.state_name, this.result.location.state_id, false, true);
        $('select[name=state_id]').append(stateOption).trigger('change');

        let cityOption = new Option(this.result.location.city_name, this.result.location.city_id, false, true);
        $('select[name=city_id]').append(cityOption).trigger('change');

    }

    reset() {
        this.resetForm();

        $('input[name=receiver_address_id]').val('');
        $('.js-order_update_address').addClass('d-none');
        this.showError('Seçili adresiniz bulunmuyor.');
        this.resetFormErrors();
    }

    showError(msg) {
        $('#receiver-address').html(msg);
    }

    resetForm() {
        this.selectIntlPhoneCode('');

        $('#address #full_name').val('');
        $('#address #company_name').val('');
        $('#address #phone').val('');
        $('#address #address').val('');
        $('#address #email').val('');
        $('#address #postal_code').val('');

        $('select[name=country_id]').val('').trigger('change');
        $('select[name=state_id]').val('').trigger('change');
        $('select[name=city_id]').val('').trigger('change');
    }

    resetFormErrors() {
        let errors = new formErrors();
        errors.resetFormStyle();
    }
}
