import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super();

        let self = this;

        let logo = '';
        if (item.logoSrc != '') {
            logo = '<img src="' + item.logoSrc + '" class="user-logo-img" alt="lgoo">';
        }

        return [
            item.id,
            '<img src="' + item.avatar + '" class="img-thumbnail rounded-circle">',
            logo,
            '<b>' + item.full_name + '</b>',
            item.company_name,
            item.email,
            self.checkButton(item.can_be_shipper),

            self.updateButton(data, item.id, 'adminUserModal'),
            self.deleteButton(item),
        ];
    }
}
