export class OrderJsonHelper {

    static parseData(orderStr) {
        let order;

        orderStr = orderStr.replaceAll("'", '\"');

        try {
            order = JSON.parse(orderStr);
        } catch (error) {
            if (error instanceof SyntaxError) {
                alert('Invalid JSON: There are invalid characters in the data within the order (product name, address information). The JSON has been logged to the console.');
                console.log(orderStr);
            } else {
                alert('Undefined error.');
            }
            return null;
        }

        return order;
    }
}
