import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super();

        let self = this;

        return [
            item.id,
            this.integrationLogo(item.integration_id),
            item.name,
            '<b>' + item.sku + '</b> ',
            self.productCodeColumn(item),
            self.convertDate(item.created_at),
            self.updateButton(data, item.id, '')
        ];
    }


    productCodeColumn(item) {
        if (item.product_code === null) {
            return '-';
        }

        const removeButton = item.product_code ? `
                <br><span class="btn btn-warning btn-circle btn-sm open-modal" data-url="` + window.baseUrl('admin/remove-product-code/' + item.id) + `" title="Ürün kodunu kaldır">
                    <i class="fas fa-times"></i>
                </span>
            ` : '';

        return '<div class="text-center">' + item.product_code + removeButton + '</div>';
    }

    convertDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('en-GB', { // Change to desired locale
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    }

    integrationLogo(id) {
        let integrationIcon = '-';
        if (id != null) {
            integrationIcon = `<img src="/img/integrations/` + id + `.png" class="vendor-img-sm" alt="integrator" title="integrator">`;
        }

        return `
            <div class="text-center">
                ` + integrationIcon + `
            </div>`;
    }
}
