import {formErrors} from '../../../forms/formErrors';

export class ProductCodeCreator {

    constructor(productCode, viewer) {
        this.viewer = viewer;
        this.productCode = productCode;

        this.form = $('#product-form');

        this.events();
    }

    events() {
        let self = this;

        $(document).off('click', '#create-product-code').on('click', '#create-product-code', function () {
            self.createNewProductCode();
        });
    }

    createNewProductCode() {
        let self = this;

        self.createProductCode()
            .then(data => {
                self.viewer.showSkuData();

                self.resetForm();
            });
    }

    resetForm() {
        $('#integration_id').val('');
        $('#product_code_name').val('');
        $('#sku').val('');

        const errors = new formErrors();
        errors.resetFormStyle();
    }

    createProductCode() {

        let self = this;

        return $.ajax({
            type: 'POST',
            url: '/admin/create-product-code',
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                product_code: self.productCode,
                integration_id: $('#integration_id').val(),
                product_code_name: $('#product_code_name').val(),
                sku: $('#sku').val(),
            }
        }).fail(function (xhr) {
            const errors = new formErrors(xhr.responseJSON.errors, self.form);
            errors.showErrors();
            //alert(xhr.responseJSON.message);
        });
    }
}
