export class ProductCodeViewer {

    constructor(productCode) {
        this.productCode = productCode;

        this.events();
    }

    events() {
        let self = this;

        $(document).off('click', '.js-remove_code').on('click', '.js-remove_code', function () {

            const order_product_code_id = $(this).data('order_product_code_id');
            self.deleteSku({
                order_product_code_id: order_product_code_id
            }).then(data => {
                self.showSkuData();
            });
        });
    }

    showSkuData() {
        let self = this;

        self.getSkuData()
            .then(data => {

                let content = '';
                $(data.result).each(function (index, item) {
                    content += self.formattedTemplate(item);
                });

                if (content != '') {
                    let templateHeader = $('#product-code-template .header').html();
                    content = templateHeader + content;
                }

                $('#sku-content').html(content);
            });
    }

    formattedTemplate(item) {
        const style = 'float:right;width:15px;margin:7px';
        let row = $('#product-code-template .body').html();
        row = row.replace(/__SKU__/g, item.sku);
        row = row.replace(/__NAME__/g, item.name);
        row = row.replace(/__INTEGRATION_ID__/g, item.integration_id);
        row = row.replace(/__ORDER_PRODUCT_CODE_ID__/g, item.id);

        let integrationName = item.integration_name != null ? item.integration_name : '-';
        row = row.replace(/__INTEGRATION_NAME__/g, integrationName);

        let icon = item.integration_id != null ? '<img src="/img/integrations/' + item.integration_id + '.png" style="' + style + '">' : '-';
        row = row.replace(/__INTEGRATION_ICON__/g, icon);

        return row;
    }

    deleteSku(data) {
        let self = this;

        return $.ajax({
            type: 'POST',
            url: '/admin/delete-product-sku',
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data,
        }).fail(function (xhr) {

            console.log(xhr.responseJSON);

        });
    }

    getSkuData() {
        let self = this;
        return $.ajax({
            type: 'POST',
            url: '/admin/show-product-sku/' + self.productCode,
            dataType: 'json',
            cache: false,
            crossDomain: true,
        }).fail(function (xhr) {
            console.log(xhr.responseJSON);
        });
    }
}
