export default class InProcess {

    constructor() {
        this.events();
    }

    events() {

        let self = this;

        $(document).off('click', '.js-change_to_in_process').on('click', '.js-change_to_in_process', function () {
            let orderId = $(this).data('id');
            let button = $(this);

            self.changeStatusToProcess(orderId, button);
        });

        $(document).off('change', 'select[name=select_shelves]').on('change', 'select[name=select_shelves]', function () {

            let data = $(this).find("option:selected").data('shelf_list');

            $(this).parent().parent().find('.shelf_1').val(data.shelf_1);
            $(this).parent().parent().find('.shelf_2').val(data.shelf_2);
            $(this).parent().parent().find('.shelf_3').val(data.shelf_3);
            $(this).parent().parent().find('.shelf_4').val(data.shelf_4);

            $(this).parent().parent().find('.js-change_to_in_process').trigger('click');
        })
    }

    getContent(order) {
        let shelfList = $('#order_table').data('shelf_list');

        let options = '';
        shelfList.forEach(function (item) {
            options += `<option value="` + item.id + `" data-shelf_list='` + JSON.stringify(item) + `'>
                    item : ` + item.id + ` |
                    Depo No : ` + item.shelf_1 + `
                    Sıra No : ` + item.shelf_2 + `
                    Kolon No : ` + item.shelf_3 + `
                    Bölüm No : ` + item.shelf_4 + `
                    </option>`;
        });

        return `
                <div class="row">

                    <div class="col">
                        List  :
                       <select class="form-control  bg-warning" name="select_shelves">
                            <option>Seçiniz</option>
                            ` + options + `
                       </select>
                    </div>
                    <div class="col">
                        Depo No :
                        <input type="text" class="form-control shelf_1" value="` + this.printIfNotNull(order.shelf_1) + `">
                    </div>
                    <div class="col">
                        Sıra No :
                        <input type="text" class="form-control shelf_2" value="` + this.printIfNotNull(order.shelf_2) + `">
                    </div>
                    <div class="col">
                        Kolon No :
                        <input type="text" class="form-control shelf_3" value="` + this.printIfNotNull(order.shelf_3) + `">
                    </div>
                    <div class="col">
                        Bölüm No :
                        <input type="text" class="form-control shelf_4" value="` + this.printIfNotNull(order.shelf_4) + `">
                    </div>
                    <div class="col">
                        <br>
                        <button type="button" class="btn btn-primary js-change_to_in_process" data-id="` + order.id + `">
                            DEPOYA KABUL ET
                        </button>
                    </div>
                </div>`;
    }

    printIfNotNull(value) {
        return (value != null ? value : '');
    }

    changeStatusToProcess(orderId, button) {
        let self = this;

        let form = button.parent().parent();

        $.ajax({
            type: 'PATCH',
            url: '/admin/orders/' + orderId + '/accept-package',
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: {
                shelf_1: form.find('.shelf_1').val(),
                shelf_2: form.find('.shelf_2').val(),
                shelf_3: form.find('.shelf_3').val(),
                shelf_4: form.find('.shelf_4').val(),
            },
        }).done(function () {

            button.parent().parent().parent().html(`
                    <div class="col-12 alert alert-success open-modal"
                        data-url="/admin/admin_orders/` + orderId + `/edit"
                        data-onload="vendorOrderModal"
                        role="button">

                        <small>
                            <b>` + orderId + `</b> nolu sipariş  <b>işlemdeki siparişler</b> bölümüne taşınmıştır.
                        </small>
                    </div>
            `);
        }).fail(function (xhr) {

            let err = 'Hata';
            if (typeof xhr.responseJSON.error != 'undefined') {
                err = xhr.responseJSON.error;
            } else if (xhr.responseJSON.message != 'undefined') {
                err = xhr.responseJSON.message;
            }
            alert(err);

        });
    }
}
