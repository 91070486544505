import {ShipmentPrice} from "../shipmentPrice";
import New from "./VendorStatuses/New.js";
import InWareHouse from "./VendorStatuses/InWareHouse";
import Labelled from "./AdminStatuses/Labelled";
import {OrderJsonHelper} from "../OrderJsonHelper";

export class VendorContent {

    constructor(dataObj) {
        this.dataObj = dataObj;
    }

    /**
     * Formatting function for row details - modify as you need
     */
    getRowContent() {

        let orderStr = this.dataObj.attr('data-order');
        let order = OrderJsonHelper.parseData(orderStr);
        if (!order) return;

        let status = parseInt(order.real_status);

        let creator;

        if ([13].includes(status)) {
            creator = new New(this.dataObj);
        }

        if ([26].includes(status)) {
            creator = new InWareHouse(this.dataObj);
        }

        if (status === 14) {
            creator = new Labelled();
        }

        if (typeof creator != 'undefined') {
            return creator.getContent(order);
        }
    }

    /**
     * REQUIRED
     *
     * Fired after row html loaded.
     */
    afterCreated() {

        let shipmentPrice = new ShipmentPrice();
        let orderId = this.dataObj.attr('id').replace('order_', '');

        shipmentPrice.showPriceEvent(orderId);
    }

}
