import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super()
        this.item = item;

        let self = this;
        return [
            item.id,
            item.name,
            this.isActive(item.is_active),
            self.updateButton(data, item.id),
        ];
    }

}
