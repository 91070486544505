export default class InWareHouse {

    constructor() {
        this.labelButtonHtml = '<i class="fas fa-barcode pr-2"></i> LABEL';
        this.events();
    }

    events(){
        let self = this;

        $(document).off('click', '.js-print-admin-label').on('click', '.js-print-admin-label', function () {

            let button = $(this);
            let data = {
                order_id: $(this).data('order_id'),
                variety_service_id: $(this).parent().parent().find('select[name=shipment_service_id]').val(),
                pickup_address_id: $(this).parent().parent().find('select[name=pickup_address]').val(),
                print_type: $(this).parent().parent().find('select[name=print_type]').val(),
                with_etgb: $(this).parent().parent().find('#with_etgb').prop('checked'),
            };

            self.printLabel(data, button);
        });
    }

    getContent(order) {

        // Create options
        let statusListStr = $('#shipping-services').attr('data-service_list');
        let statusList = JSON.parse(statusListStr);
        let options = '';

        $.each(statusList, function (index, item) {

            let isReturn = false;
            if (order.return_of_id != 'null') {
                isReturn = true;
            }

            options +=
                '<option value="' + item.id + '" ' + (order.vendor_service_id == item.id ? ' selected="selected"   style="background:#eee;font-weight:bold;color:#f00" ' : '') + '>' +
                item.shiping_name + ' - ' + item.name + (order.vendor_service_id == item.id ? ' - Auto Selected ' : '')
                + '</option>';
        });

        let self = this;
        let content = $('#child-row-content').html();

        content = content.replace(/__OPTIONS__/g, options);
        content = content.replace(/__BUTTON_LABEL__/g, self.labelButtonHtml);
        content = content.replace(/__ORDER_ID__/g, order.id);
        return content;
    }

    printLabel(data, button) {
        let self = this;

        button.html(`
            <div class="spinner-border" role="status">
                <span class="sr-only">
                    Loading...
                </span>
            </div>
        `);

        $.ajax({
            type: 'POST',
            url: '/vendor-site/print-label',
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data,
        }).done(function (result) {

            button.attr('data-status', 'success');

            let content = `
                Labelled. Tracking number : <b>` + result.tracking_number + `</b><br>
                <small><b>` + data.order_id + `</b> nolu sipariş  <b>işlemdeki siparişler</b> bölümüne taşınmıştır.</small>
            `;

            if (typeof result.new_cost != "undefined") {
                content += '<div class="text-danger">New cost : ' + result.new_cost + '</div>'
            }

            button.parent().parent().html(`
                    <div class="col-12 alert alert-success open-modal"
                        data-url="/admin/admin_orders/` + data.order_id + `/edit"
                        data-onload="vendorOrderModal"
                        role="button">
                        ` + content + `
                    </div>
            `);
        }).fail(function (xhr) {

            button.attr('data-status', 'failed');
            self.showAdminError(xhr.responseJSON, button);
            button.html(self.labelButtonHtml);

        });
    }

    /**
     * Shows admin error messages
     */
    showAdminError(responseJSON, button) {
        let html = '';

        if (typeof responseJSON.message !== 'undefined') {
            html = '<li>' + responseJSON.message + '</li>';
        }

        if (html === '') {
            if (typeof responseJSON == 'string') {
                html = '<li>' + msg.message + '</li>';
            } else if (typeof responseJSON == 'object') {

                $.each(responseJSON, function (index, item) {
                    html += '<li>' + item + '</li>';
                });
            }
        }

        button.parent().parent().parent().parent().find('.js-errors').removeClass('d-none').html('<ol>' + html + '</ol>');
    }
}
