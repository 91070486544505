import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable{

    constructor(item, data) {

        super()

        let self = this;

        return [
            item.id,
            item.variable,
            item.value,
            self.updateButton(data, item.id),
            self.deleteButton(item),
        ];
    }
}
