import $ from "jquery";
import {formErrors} from "../../../forms/formErrors";

export class CustomerAccount {

    constructor() {
        this.button = $('#customer-account-form .js-save-customer-account');
        this.buttonText = this.button.html();
        this.events();
    }

    events() {
        let self = this;

        self.button.on('click', function () {
            self.update();
        });

        $('.js-save-vbt-data').off('click').on('click', function () {
           self.updateVbt();
        });

        self.currencyEvent();
    }

    updateVbt(){

        const button =  $('.js-save-vbt-data');

        button.attr('data-html', button.html());

        button.html('<i class="fas fa-circle-notch fa-spin"></i> Processing...');

        const data = {
            invoice_number : $('#vbt_invoice_number').val(),
            ettn : $('#vbt_ettn').val(),
            order_id :$('.js-save-vbt-data').data('order_id')
        }

        $.ajax({
            type: 'PUT',
            url: window.baseUrl('admin/order-update-vbt'),
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data,
        }).done(function (result) {

            button.html('Updated');

        }).fail(function (xhr) {
            let errorList = xhr.responseJSON.errors;
            new formErrors(errorList, $('#customer-account-form'));
        });
    }

    /**
     * Must be fired only in create
     */
    currencyEvent() {

        let target = $('#customer-account-form .js-account-curency');
        let select = $('#customer-account-form select[name=shipment_currency]');
        select.change(function () {

            let value = $(this).find(':selected').attr('data-value');

            // data-value is not set in update form!
            if (typeof value != 'undefined') {
                $('#currency_value').val(value);
            }

            let sign = $(this).find(':selected').data('sign');
            target.text(sign);
        });
        target.text(select.find(':selected').data('sign'));
    }

    loading() {
        this.button.html(`
            <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        `);
    }

    /**
     * "accountant_" öneki çakışmaları önlemek için kullanıldı.
     */
    getData() {
       return {
            'shipment_cost': $('#customer-account-form input[name=accountant_shipment_cost]').val(),
            'shipment_actual_desi': $('#customer-account-form input[name=accountant_shipment_actual_desi]').val(),
            'shipment_actual_cost': $('#customer-account-form input[name=accountant_shipment_actual_cost]').val(),
            'currency_value': $('#customer-account-form input[name=accountant_currency_value]').val(),
            'invoice_no': $('#customer-account-form input[name=accountant_invoice_no]').val(),
            'is_paid': $('#customer-account-form input[name=accountant_is_paid]:checked').val() ?? 0,
        };
    }

    update() {

        let self = this;
        self.loading();

        let url = this.button.data('url');

        $.ajax({
            type: 'PUT',
            url: url,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: self.getData(),
        }).done(function (result) {
            self.button.html(self.buttonText);
        }).fail(function (xhr) {
            let errorList = xhr.responseJSON.errors;
            new formErrors(errorList, $('#customer-account-form'));
        });
    }
}
