export class OnSuccess{

    constructor(form) {
        this.form = form
    }

    init(data){
        console.log(this.form)
        this.form.form.closest('tr').remove();
    }
}
