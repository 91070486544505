import {ProductCodeSearcher} from "./catalog/productCodeSearcher ";
import {ProductCodeViewer} from "./catalog/ProductCodeViewer";
import {ProductCodeCreator} from "./catalog/productCodeCreator";

export class FormModalOnLoad {

    constructor(data) {
        const productCode = document.getElementById('product_code').value;

        if(productCode == ''){
            alert('productCode required!');
        }
        this.viewer = new ProductCodeViewer(productCode);
        this.creator = new ProductCodeCreator(productCode, this.viewer);
        this.searcher = new ProductCodeSearcher(productCode, this.viewer);

        this.events();
        this.viewer.showSkuData();
    }

    events() {
        let self = this;
    }
}
