import $ from "jquery";

export class OrderStatus {

    constructor() {

        this.showTrackingUrl =  $('.js-tracking-results').data('show_tracking_url');

        this.results = $('.js-tracking-results');

        this.events();
    }

    events() {
        let self = this;
    }

    loading() {

        this.results.html(`
            <div class="d-flex justify-content-center">
                <div class="spinner-border m-5" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        `);
    }

    updateTab() {
        let self = this;

        self.loading();

        $.ajax({
            type: 'POST',
            url: self.showTrackingUrl,
            dataType: 'json',
            cache: false,
            crossDomain: true,
        }).done(function (result) {

            self.showResults(result);

        }).fail(function (xhr) {

            self.showErrors(xhr.responseJSON);

        });
    }

    showErrors(json) {
        let msg = '';
        if (typeof json.message != 'undefined') {
            msg = json.message;
        }
        if (typeof json.error != 'undefined') {
            msg = json.error;
        }
        $('.js-tracking-results').html(`
            <div class="alert alert-danger">
                <h5>ERROR</h5>
                <p>` + msg + `</p>
            </div>
        `);
    }

    showResults(result) {
        $('.js-remove_on_load').remove();

        let template = $('.js-result-template').html();
        let templateBody = $('.js-result-template tbody').html();
        let newBody = '';
        $.each(result.trackingResults, function (index, item) {
            let row = templateBody

            row = row.replace(/__DATE__/g, item.date)
            row = row.replace(/__TIME__/g, item.time)
            row = row.replace(/__TYPE_CODE__/g, item.statusCode)
            row = row.replace(/__DESCRIPTION__/g, item.status)
            row = row.replace(/__WHERE__/g, item.location)
            newBody += row
        });

        $('.js-tracking-results').html(template);
        $('.js-tracking-results tbody').html(newBody);
    }
}
