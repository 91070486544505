import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable{

    constructor(item, data) {

        super()

        let self = this;
        return [
            item.id,
            '<b>' + item.order_id + '</b>',
            item.is_success,
            item.integration_id,
            item.log,
            item.created_at,
        ];
    }
}
