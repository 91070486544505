import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable {

    constructor(item, data) {

        super()

        let self = this;
        return [
            item.id,
            item.product_code,
            item.brand_name,
            '<b>' + item.name + '</b>',
            item.category_name,
            self.isActive(item.is_active),
            item.marketplaceList,
            self.updateButton(data, item.id, 'product'),
            self.deleteButton(item ),
        ];
    }
}
