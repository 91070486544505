import InProcess from "./AdminStatuses/InProcess";
import InWareHouse from "./AdminStatuses/InWareHouse";
import Labelled from "./AdminStatuses/Labelled";
import {OrderJsonHelper} from "../OrderJsonHelper";

export class AdminContent {

    constructor(dataObj) {
        this.dataObj = dataObj;
    }

    getRowContent() {

        let tdContent = '';

        let status = parseInt(this.dataObj.attr('data-real_status'));

        let orderStr = this.dataObj.attr('data-order');
        let order = OrderJsonHelper.parseData(orderStr);
        if (!order) return;

        let creator;

        if ([28, 29, 32].includes(status)) {
            creator = new InProcess();
        }

        if (status === 26) {
            creator = new InWareHouse();
        }

        if (status === 14) {
            creator = new Labelled();
        }

        if (typeof creator != 'undefined') {
            return creator.getContent(order);
        }
    }

    /**
     * REQUIRED
     *
     * Fired after row html loaded.
     */
    afterCreated() {

    }

}
