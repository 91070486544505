import {FormatDataTable} from '../formatDataTable';

export class DataTableOnLoad extends FormatDataTable{

    constructor(item, data) {

        super();

        let self = this;

        return [
            item.id,
            '<b>' + item.name + '</b> ',
            item.vkn,
            self.isActive(item.is_active),
            self.updateButton(data, item.id, ''),
            self.deleteButton(item),
        ];
    }
}
