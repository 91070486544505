export class OnSuccess {

    constructor(form) {
        this.form = form
    }

    init(data) {
        // alert( window.baseUrl());
        $('body').append('<span class="btn btn-success btn-circle btn-sm open-modal jsnew" data-url="/admin/admin_products/' + data.id + '/edit" data-onload="product"><i className="fas fa-pen"></i></span>');
        $('.jsnew').trigger('click').remove();
    }
}
