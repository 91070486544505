export class ProductCodeSearcher {

    constructor(productCode, viewer) {
        this.viewer = viewer;
        this.productCode = productCode;

        this.events();
    }

    events() {
        let debounceTimeout;
        let self = this;

        $(document).off('input', '#search_string').on('input', '#search_string', function () {

            self.resetEmpty();

            if (typeof self.productCode === 'undefined') {
                alert('Önce product code alanını giriniz.');
            } else {

                clearTimeout(debounceTimeout);

                debounceTimeout = setTimeout(function () {

                    self.showResults([]);

                    self.getValues();
                }, 300); // Adjust the delay (in milliseconds) as needed
            }
        });

        $(document).off('change', '#search_where').on('change', '#search_where', function () {

            self.resetEmpty();

            if (typeof self.productCode === 'undefined') {
                alert('Önce product code alanını giriniz.');
            } else {

                self.showResults([]);

                self.getValues();
            }
        });

        $(document).off('click', '.js-add_product_code').on('click', '.js-add_product_code', function () {

            self.resetSearchText();

            self.showResults([]);

            const sku = $(this).data('sku');
            const integrationId = $(this).data('integration_id');

            try {
                self.saveCode({
                    sku: sku,
                    product_code: self.productCode,
                    integration_id: integrationId
                });
            } catch (error) {
                alert(error.message);
            }
        });
    }

    resetSearchText() {
        $('#search_string').val('');
    }

    showResults(result) {
        let content = '';
        let self = this;
        const style = 'float:right;width:15px;margin:7px';
        $(result).each(function (index, item) {
            let row = $('#search-result-row-template').html();
            row = row.replace(/__SKU__/g, item.sku);
            row = row.replace(/__INTEGRATION_IMG__/g, '<img src="/img/integrations/' + item.integration_id + '.png" style="' + style + '">');
            row = row.replace(/__INTEGRATION_NAME__/g, item.integration_name);
            row = row.replace(/__INTEGRATION_ID__/g, item.integration_id);
            row = row.replace(/__NAME__/g, item.name);
            content += row;
        })

        $('#search_results').html(content);
    }

    getValues() {
        let self = this;

        const data = {
            search: $('#search_string').val(),
            where: $('#search_where').val()
        }

        $.ajax({
            type: 'POST',
            url: window.baseUrl('admin/search-product') ,
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data,
        }).done(function (result) {

            if (result.length == 0) {
                self.showEmpty();
            }

            self.showResults(result)

        }).fail(function (xhr) {

            console.log(xhr.responseJSON);

        });
    }

    saveCode(data) {
        let self = this;

        $.ajax({
            type: 'POST',
            url: window.baseUrl('admin/product-code-sku-update'),
            dataType: 'json',
            cache: false,
            crossDomain: true,
            data: data,
        }).done(function (result) {

            if (!result.result) {
                alert('err');
            }

            self.viewer.showSkuData();

        }).fail(function (xhr) {

            console.log(xhr.responseJSON);

        });
    }

    showEmpty() {
        $('#search_not_found').removeClass('d-none');
    }

    resetEmpty() {
        $('#search_not_found').addClass('d-none');
    }

}
